<template>
  <header class="header">
    <div class="container flex">
      <div class="logo">
        <router-link to="/" class="flex logo__link" :title="$store.state.title">
          <img
            :src="logo"
            :alt="$store.state.title"
            class="logo__img"
            width="58"
            height="49"
          />
          <span class="flex logo__text">
            <span>{{ $store.state.title }} </span>
            <span>{{ $store.state.district }} </span>
            <span>{{ $store.state.slogan }} </span>
            <span></span>
          </span>
        </router-link>
      </div>
      <div class="flex flex-nav-right">
        <AppNavbar />
        <div class="change-font">
          <ul class="flex">
            <li @click="increaseFont">क+</li>
            <li @click="decreaseFont">क-</li>
          </ul>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { defineAsyncComponent } from "vue";
import logo from "./../../assets/images/nepal-gov.svg";
const AppNavbar = defineAsyncComponent(() => import("../shared/AppNavbar.vue"));
export default {
  name: "AppHeader",
  components: {
    AppNavbar,
  },
  data: function () {
    return {
      logo: logo,
    };
  },
  methods: {
    increaseFont: function () {
      var currentSizeBody = document.querySelector("body").style.fontSize;
      if (currentSizeBody == "") currentSizeBody = 16; //default font size
      console.log(currentSizeBody);
      var newSizeBody = parseFloat(currentSizeBody) * 1.05 + "px";
      document.querySelector("body").style.fontSize = newSizeBody;

      var currentSizePara = document.querySelector("p").style.fontSize;
      if (currentSizePara == "") currentSizePara = 18; //default font size
      console.log(currentSizePara);
      var newSizePara = parseFloat(currentSizePara) * 1.05 + "px";
      document.querySelector("p").style.fontSize = newSizePara;
    },
    decreaseFont: function () {
      var currentSizeBody = document.querySelector("body").style.fontSize;
      if (currentSizeBody == "") currentSizeBody = 16; //default font size
      console.log(currentSizeBody);
      var newSizeBody = parseFloat(currentSizeBody) * 0.95 + "px";
      document.querySelector("body").style.fontSize = newSizeBody;

      var currentSizePara = document.querySelector("p").style.fontSize;
      if (currentSizePara == "") currentSizePara = 18; //default font size
      console.log(currentSizePara);
      var newSizePara = parseFloat(currentSizePara) * 0.95 + "px";
      document.querySelector("p").style.fontSize = newSizePara;
    },
  },
};
</script>

<style lang="scss" scoped></style>
